import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild('navbutton', null) navButton: ElementRef;
  toggleNavMenu = false;

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit() {
  }
  
  onClick(elementId: string): void {
    this.navButton.nativeElement.className = 'collapse navbar-collapse';
    this.changeChildNodeAlignment();
    this.viewportScroller.scrollToAnchor(elementId);
  }

   changeChildNodeAlignment(): void {
     if (!this.toggleNavMenu) {
       this.navButton.nativeElement.children[0].className = 'navbar-nav ml-auto';
     } else {
       this.navButton.nativeElement.children[0].className = 'navbar-nav ml-auto';
     }
     this.toggleNavMenu = !this.toggleNavMenu;
   }
}
