import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  submitted: boolean;

  constructor(public formBuilder : FormBuilder) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      txtName: ['',[Validators.required,Validators.minLength(4)]],
      txtEmail: ['',[Validators.required, Validators.email]],
      txtSubject: ['',[Validators.required,Validators.minLength(8)]],
      txtMsg: ['',Validators.required]
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }

  fnSendMsg()  {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) { 
        return; 
      }

      // display form values on success
      alert('Message Sent Success :) \n' + JSON.stringify(this.contactForm.value, null, 4));
  }

  fnCancel() {
    //alert('Cancel..!');
    this.submitted = false;
    this.contactForm.reset();
  }

}
